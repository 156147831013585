import React from "react";
import useAuth from "../hooks/useAuth";
import { Link, useNavigate } from "react-router-dom";
import { HOST } from "utils/config";
import useCart from "../hooks/useCart";

export default function Header() {
  const { isAuthenticated, user, logout } = useAuth();
  const { cartCount } = useCart();
  const navigate = useNavigate();

  const onLoginClick = async (event) => {
    if (isAuthenticated) return;
    navigate("/account");
  };

  const onLogoutClick = async (event) => {
    try {
      await logout();
    } catch (error) {}
  };

  return (
    <header className="htc__header bg--white">
      <div id="sticky-header-with-topbar" className="mainmenu__wrap is-sticky">
        <div className="container">
          <div className="row">
            <div className="col-11">
              <div className="logo" style={{ overflow: "hidden" }}>
                <Link to="/">
                  <img
                    width="143"
                    src={HOST + "media/logo.png"}
                    alt="logo images"
                  />
                </Link>
              </div>
            </div>
            <div className="col-1">
              <div className="header__right d-flex justify-content-end">
                <div
                  className="log__in"
                  style={{ cursor: "pointer", position: "relative" }}
                >
                  <div
                    className="accountbox-trigger cursor-pointer"
                    onClick={onLoginClick}
                  >
                    <i
                      className={`zmdi zmdi-account-o ${isAuthenticated && "active"}`}
                    ></i>
                    {isAuthenticated && (
                      <nav className="main__menu__nav d-none d-lg-block">
                        <ul style={{ height: "100%" }}>
                          <li className="drop">
                            <ul className="dropdown__menu">
                              {isAuthenticated && (
                                <li
                                  style={{
                                    maxWidth: "250px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    cursor: "initial",
                                  }}
                                >
                                  {user.phone}
                                </li>
                              )}
                              {user?.admin && (
                                <li>
                                  <Link to="admin">Ադմին</Link>
                                </li>
                              )}
                              <li onClick={onLogoutClick}>
                                <Link>Դուրս գալ</Link>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </nav>
                    )}
                  </div>
                </div>
                <div className="shopping__cart" style={{ cursor: "pointer" }}>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a onClick={() => navigate("/cart")}>
                    <i className="zmdi zmdi-shopping-basket"></i>
                  </a>
                  {cartCount > 0 && (
                    <div className="shop__qun">
                      <span>{cartCount}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="mobile-menu d-block d-lg-none"></div>
        </div>
      </div>
    </header>
  );
}
