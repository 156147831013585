import PhoneInput from "react-phone-number-input";
import React, { useState } from "react";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import useCart from "../../hooks/useCart";

export default function LoginRegisterForm() {
  const [phone, setPhone] = useState();
  const { loginRegister } = useAuth();
  const [errors, setErrors] = useState(null);
  const navigate = useNavigate();
  const { cartItems } = useCart();

  const removeErrors = async (id) => {
    const e = { ...errors };
    e && delete e[id];
    setErrors(e);
  };

  const onSubmit = async (event) => {
    try {
      event.preventDefault();

      const password = event.target.password.value;
      await loginRegister(phone, password, cartItems);

      event.target.reset();
      setErrors(null);
      navigate("/");
    } catch (error) {
      setErrors(error);
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="single-input">
        <PhoneInput
          defaultCountry="AM"
          className="cr-round--lg"
          placeholder="Հեռախոսահամար"
          value={phone}
          onChange={(e) => {
            setPhone(e);
            removeErrors("phone");
          }}
        />
        <small className="text-danger">
          {errors && errors["phone"] && errors["phone"][0]}
        </small>
      </div>
      <div className="single-input">
        <input
          id="password"
          className="cr-round--lg"
          type="password"
          placeholder="Գաղտնաբառ"
          onChange={() => removeErrors("password")}
        />
        <small className="text-danger d-block">
          {errors && errors["password"] && errors["password"][0]}
          {errors && errors["detail"]}
        </small>
        <small
          className="float-left cursor-pointer"
          style={{ textDecoration: "underline" }}
          onClick={() => navigate("/enter-phone")}
        >
          Մոռացե՞լ եք գաղտնաբառը
        </small>
      </div>
      <div className="single-input" style={{ marginTop: "40px" }}>
        <button type="submit" className="food__btn">
          <span>Շարունակել</span>
        </button>
      </div>
    </form>
  );
}
