import { useCallback, useMemo } from "react";
import { useSelector } from "../redux/store";

const useCart = () => {
  const { cart } = useSelector((state) => state.cart);
  const { deliveryPrice } = useSelector((state) => state.settings);

  const cartCount = useMemo(() => {
    return cart.cart_items.reduce((acc, item) => acc + item.quantity, 0);
  }, [cart]);

  const getTotals = useCallback(() => {
    let subtotal = cart.cart_items.reduce(
      (acc, item) => acc + item.product.price * item.quantity,
      0,
    );
    return {
      subtotal,
      total: subtotal + deliveryPrice,
    };
  }, [cart, deliveryPrice]);

  return {
    cartCount,
    delivery: deliveryPrice,
    cartItems: cart.cart_items ?? [],
    ...getTotals(cart),
  };
};

export default useCart;
