import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect } from "react";
import axios from "utils/axios";
import { useAsyncFetch } from "utils/useAsyncFetch";

export default function AdminCreateCategpry() {
  const { id } = useParams();
  const navigate = useNavigate();

  const { fetchData, data: category } = useAsyncFetch(`/categories/${id}/`);

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id, fetchData]);

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      const name = event.target.name.value;
      id
        ? await axios.put(`/categories/edit/${id}/`, { name })
        : await axios.post("/categories/create/", { name });
      navigate(-1);
    },
    [navigate, id],
  );

  return (
    <>
      <h1>{id ? "Խմբագրել" : "Ստեղծել"} կատեգորիա</h1>
      <form onSubmit={onSubmit}>
        <div className="form-group">
          <label htmlFor="name">Անուն:</label>
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            required
            defaultValue={category?.name}
          />
        </div>
        <button type="submit" className="btn btn-karkandak">
          {id ? "Խմբագրել" : "Ստեղծել"}
        </button>
      </form>
    </>
  );
}
