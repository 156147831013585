import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { dispatch } from "../store";

const initialState = {
  cart: { cart_items: [] },
  error: null,
};

const slice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    resetCart(state) {
      state.cart = { cart_items: [] };
    },
    addCart(state, action) {
      state.cart = action.payload;
    },
    addToCartSuccess(state, { payload }) {
      const index = state.cart.cart_items.findIndex(
        (item) => item.product.id === payload.product.id,
      );
      if (index === -1) {
        state.cart.cart_items.push(payload);
      } else {
        state.cart.cart_items[index].id = payload.id;
        state.cart.cart_items[index].quantity += payload.quantity;
      }
    },
    removeFromCartSuccess(state, { payload }) {
      const indexToRemove = state.cart.cart_items.findIndex(
        (item) => item.product.id === payload.product.id,
      );
      if (indexToRemove !== -1) {
        state.cart.cart_items.splice(indexToRemove, 1);
      }
    },
    hasError(state, { payload }) {
      state.error = payload;
    },
  },
});

export const { addCart, resetCart } = slice.actions;

export default slice.reducer;

export function addToCart(product, quantity, isAuthenticated) {
  return async () => {
    if (isAuthenticated) {
      const params = { product_id: product.id, quantity };
      try {
        const response = await axios.post("/cart/add/", params);
        dispatch(
          slice.actions.addToCartSuccess({
            id: response.data.id,
            product,
            quantity,
          }),
        );
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    } else {
      dispatch(
        slice.actions.addToCartSuccess({
          product,
          quantity,
        }),
      );
    }
  };
}

export function removeFromCart(cartItem, isAuthenticated) {
  return async () => {
    if (isAuthenticated) {
      try {
        await axios.delete(`/cart/cart-item/${cartItem.id}`);
        dispatch(slice.actions.removeFromCartSuccess(cartItem));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    } else {
      dispatch(slice.actions.removeFromCartSuccess(cartItem));
    }
  };
}
