import React from "react";
import Products from "./Products/Products";
import { dispatch } from "redux/store";
import { useQuery } from "hooks/useQuery";
import { openSnackbar } from "redux/slices/ui";
import { Outlet, useNavigate } from "react-router-dom";

export default function Home() {
  const query = useQuery();
  const navigate = useNavigate();

  if (query.get("payment_status") === "success") {
    dispatch(openSnackbar({ message: "Պատվերը ստեղծված է", type: "success" }));
    navigate("/");
  } else if (query.get("payment_status") === "error") {
    dispatch(
      openSnackbar({ message: query.get("description"), type: "error" }),
    );
    navigate("/");
  }

  return (
    <div className="wrapper" id="wrapper">
      <Products />
      <Outlet />
    </div>
  );
}
