import "react-phone-number-input/style.css";
import LoginRegisterForm from "home/Account/LoginRegisterForm";
import OverlayBox from "../../components/OverlayBox";

export default function Account() {
  return (
    <OverlayBox>
      <ul className="nav accountbox__filters" id="myTab" role="tablist">
        <li>
          <a
            id="log-tab"
            role="tab"
            href="#"
            style={{ pointerEvents: "none" }}
            aria-controls="log"
            aria-selected="true"
          >
            Մուտք
          </a>
        </li>
        <li>
          <a
            id="profile-tab"
            role="tab"
            href="#"
            style={{ pointerEvents: "none" }}
            aria-controls="profile"
            aria-selected="false"
          >
            Գրանցվել
          </a>
        </li>
      </ul>
      <div className="accountbox__inner tab-content" id="myTabContent">
        <div
          className="accountbox__register tab-pane fade show active"
          id="log"
          role="tabpanel"
          aria-labelledby="profile-tab"
        >
          <LoginRegisterForm />
        </div>
      </div>
    </OverlayBox>
  );
}
