import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";

import usersReducer from "./slices/users";
import productsReducer from "./slices/products";
import uiReducer from "./slices/ui";
import cartReducer from "./slices/cart";
import orderReducer from "./slices/orders";
import settingsReducer from "./slices/settings";

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const rootReducer = combineReducers({
  users: usersReducer,
  products: productsReducer,
  ui: uiReducer,
  cart: cartReducer,
  orders: orderReducer,
  settings: settingsReducer,
});

export { rootPersistConfig, rootReducer };
