import axios from "axios";
import { HOST_API } from "./config";
import { setSession } from "./jwt";
import { LOCALIZATIONS } from "./localizations";

const axiosInstance = axios.create({
  baseURL: HOST_API,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error?.response || {};

    if (status === 401) {
      setSession(null);
      return Promise.reject();
    }

    let errorMessage =
      (error.response && error.response.data) || "Ինչ որ բան այն չէ";
    if (errorMessage instanceof Array) {
      errorMessage = errorMessage.map((item) => LOCALIZATIONS[item] || item);
    } else if (typeof errorMessage === "object") {
      for (const key in errorMessage) {
        if (errorMessage.hasOwnProperty(key)) {
          if (errorMessage[key] instanceof Array) {
            errorMessage[key] = errorMessage[key].map(
              (item) => LOCALIZATIONS[item] || item,
            );
          } else if (typeof errorMessage[key] === "string") {
            errorMessage[key] =
              LOCALIZATIONS[errorMessage[key]] || errorMessage[key];
          }
        }
      }
    }

    return Promise.reject(errorMessage);
  },
);

export default axiosInstance;
