import { useNavigate } from "react-router-dom";

export default function OverlayBox({ children }) {
  const navigate = useNavigate();

  return (
    <div className={`accountbox-wrapper`}>
      <div className="body-overlay" onClick={() => navigate("/")}></div>
      <div className="accountbox text-left">
        {children}
        <span className="accountbox-close-button" onClick={() => navigate("/")}>
          <i className="zmdi zmdi-close"></i>
        </span>
      </div>
    </div>
  );
}
