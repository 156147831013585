import { dispatch, useSelector } from "redux/store";
import { getItems, updateItem } from "redux/slices/orders";
import { useEffect, useState } from "react";
import {
  ITEMS_PER_PAGE,
  ORDER_STATUS_COLORS,
  ORDER_STATUSES,
  PAYMENT_METHODS,
} from "utils/config";
import Table from "../../components/Table";
import { useNavigate } from "react-router-dom";
import { formatForPreview } from "utils/format";

export default function AdminOrders() {
  const { items, count } = useSelector((state) => state.orders);
  const [page, setPage] = useState(1);
  const rowsPerPage = ITEMS_PER_PAGE;
  const navigate = useNavigate();

  const tableConfig = [
    {
      key: "id",
      name: "ID",
    },
    {
      name: "կարգավիճակ",
      mapper: {
        key: "status",
        value: (item) => ORDER_STATUSES[item],
      },
    },
    {
      name: "հեռախոսահամար",
      mapper: {
        key: "user",
        value: (item) => item.phone,
      },
    },
    {
      name: "Առաքման ժամանակ",
      mapper: {
        key: "delivery_time",
        value: (timestamp) => {
          return formatForPreview(timestamp);
        },
      },
    },
    {
      name: "Վճարման եղանակ",
      mapper: {
        key: "payment_method",
        value: (item) => PAYMENT_METHODS[item],
      },
    },
    {
      name: "Գին",
      mapper: {
        key: "cart",
        value: (item) => `${item.total}֏`,
      },
    },
    {
      name: "հասցե",
      mapper: {
        key: "address",
        value: (item) => item.address_line,
      },
    },
    {
      name: "Պատվերի ժամանակ",
      mapper: {
        key: "order_time",
        value: (timestamp) => {
          return formatForPreview(timestamp);
        },
      },
    },
  ];

  useEffect(() => {
    dispatch(
      getItems({
        limit: rowsPerPage,
        offset: (page - 1) * rowsPerPage,
      }),
    );
  }, [page, rowsPerPage]);

  const updateOrder = (id, status) => {
    dispatch(updateItem(id, status));
  };

  return (
    <>
      <h1>Պատվերներ</h1>
      <Table
        list={items}
        page={page}
        onPageChange={setPage}
        rowsPerPage={rowsPerPage}
        count={count}
      >
        <thead>
          <tr>
            {tableConfig.map((config, index) => {
              return <th key={index}>{config.name}</th>;
            })}
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {items?.map((item, index) => {
            return (
              <tr
                key={index}
                onClick={(e) => navigate(`/admin/orders/${item.id}`)}
                className="cursor-pointer"
                style={{ backgroundColor: ORDER_STATUS_COLORS[item.status] }}
              >
                {tableConfig.map((config, index) => {
                  return (
                    <td key={index}>
                      {config.mapper
                        ? config.mapper.value(item[config.mapper.key])
                        : item[config.key]}
                    </td>
                  );
                })}
                {[
                  {
                    status: "cancelled",
                    title: "Չեղարկել",
                  },
                  {
                    status: "done",
                    title: "Ավարտել",
                  },
                ].map((data) => (
                  <td>
                    {item.status === "in_progress" && (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          updateOrder(item.id, data.status);
                        }}
                      >
                        {data.title}
                      </button>
                    )}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}
