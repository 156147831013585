import { useCallback, useState } from "react";
import axios from "../utils/axios";

const defaultResponseMapper = (res) => res;

export const useAsyncFetch = (url, responseMapper = defaultResponseMapper) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = useCallback(
    async (params = {}) => {
      setLoading(true);
      try {
        const response = await axios.get(url, { params });
        setData(responseMapper(response.data));
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setError, setData, url, responseMapper],
  );

  return { fetchData, data, loading, error };
};
