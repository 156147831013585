import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  items: null,
  count: null,
};

const slice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    getItemsSuccess(state, action) {
      state.items = action.payload.results;
      state.count = action.payload.count;
    },

    updateSuccess(state, action) {
      const index = state.items.findIndex(
        (item) => item.id === action.payload.id,
      );
      if (index !== -1) {
        state.items[index] = { ...state.items[index], ...action.payload };
      }
    },

    hasError(state, action) {
      state.items = null;
      state.count = null;
    },
  },
});

// Reducer
export default slice.reducer;

export function getItems(params) {
  return async () => {
    try {
      const response = await axios.get("/orders/list/", {
        params,
      });
      dispatch(slice.actions.getItemsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateItem(id, status) {
  return async () => {
    try {
      const response = await axios.put(`/orders/${id}/`, { status });
      dispatch(slice.actions.updateSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
