import moment from "moment";
import "moment/locale/hy-am";

moment.locale("hy-am");

export function formatOrderDate(date) {
  const today = moment().startOf("day");
  const object = moment(date);
  const formattedDate = object.format("MMMM DD, dddd");
  if (object.isSame(today)) {
    return `Այսօր, ${formattedDate}`;
  } else {
    return formattedDate;
  }
}

export function formatOrderTime(time) {
  return moment.utc(time, "HH:mm").local().format("HH:mm");
}

export function formatForPreview(time) {
  return moment(time).format("DD/MM/YYYY HH:mm");
}
