export default function Pagination({ page, count, rowsPerPage, onPageChange }) {
  const pageCount = Math.ceil((count || 0) / rowsPerPage);
  const pageNumbers = [...Array(pageCount).keys()].map((i) => i + 1);

  return (
    pageCount > 0 && (
      <nav aria-label="Page navigation">
        <ul className="pagination">
          <li className="page-item">
            <button
              className="page-link"
              onClick={() => onPageChange(page - 1)}
              disabled={page === 1}
            >
              Նախորդ
            </button>
          </li>
          {pageNumbers.map((p) => (
            <li key={p} className={`page-item ${page === p ? "active" : ""}`}>
              <button className="page-link" onClick={() => onPageChange(p)}>
                {p}
              </button>
            </li>
          ))}
          <li className="page-item">
            <button
              className="page-link"
              onClick={() => onPageChange(page + 1)}
              disabled={page === pageCount}
            >
              Հաջորդ
            </button>
          </li>
        </ul>
      </nav>
    )
  );
}
