import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";

const initialState = {
  snackbar: {
    open: false,
    message: null,
    type: null,
    timer: null,
  },
};

const slice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    openSnackbar(state, { payload }) {
      if (state.snackbar.timer) clearTimeout(state.snackbar.timer);

      state.snackbar.open = true;
      state.snackbar.message = payload.message;
      state.snackbar.type = payload.type || "success";

      state.snackbar.timer = setTimeout(() => {
        dispatch(closeSnackbar());
      }, payload.timer || 3000);
    },
    closeSnackbar(state) {
      state.snackbar.open = false;
    },
  },
});

export const { openSnackbar, closeSnackbar } = slice.actions;

export default slice.reducer;
