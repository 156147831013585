import { formatOrderDate, formatOrderTime } from "utils/format";
import { useCallback, useEffect, useState } from "react";
import axios from "../../utils/axios";
import { Controller } from "react-hook-form";

export default function OrderTime({ control, setValue }) {
  const [orderTimes, setOrderTimes] = useState([]);
  const [orderDateTimes, setOrderDateTimes] = useState(null);

  const setInitialTime = useCallback(
    (orderDateTimes, date) => {
      const times = orderDateTimes[date];
      setOrderTimes(times);
      if (times && times.length) {
        setValue("date_time.time", times[0]);
      }
    },
    [setValue],
  );

  useEffect(() => {
    (async () => {
      const response = await axios.get("/orders/date-times/");
      const data = response.data["data"];
      setOrderDateTimes(data);
      const dates = Object.keys(data);
      if (dates.length) {
        const firstDate = dates[0];
        setValue("date_time.date", firstDate);
        setInitialTime(data, firstDate);
      }
      return data;
    })();
  }, [setValue, setInitialTime]);

  return (
    <div className="checkout-form">
      <div className="row">
        <Controller
          name="date_time.date"
          control={control}
          render={({ field }) => (
            <select
              {...field}
              className="col-md-7 mr-md-3 mb--20"
              onChange={(event) => {
                field.onChange(event);
                setInitialTime(orderDateTimes, event.target.value);
              }}
            >
              {orderDateTimes &&
                Object.keys(orderDateTimes).map((date, index) => (
                  <option key={index} value={date}>
                    {formatOrderDate(date)}
                  </option>
                ))}
            </select>
          )}
        />
        <Controller
          name="date_time.time"
          control={control}
          render={({ field }) => (
            <select {...field} className="col-md-3">
              {orderTimes.map((time, index) => (
                <option key={index} value={time}>
                  {formatOrderTime(time)}
                </option>
              ))}
            </select>
          )}
        />
      </div>
    </div>
  );
}
