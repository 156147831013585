import * as Yup from "yup";
import { LOCALIZATIONS } from "../../utils/localizations";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "../../utils/axios";
import OverlayBox from "../../components/OverlayBox";
import { useQuery } from "../../hooks/useQuery";
import { dispatch } from "../../redux/store";
import { openSnackbar } from "../../redux/slices/ui";
import { useNavigate } from "react-router-dom";

export default function EnterNewPassword() {
  const query = useQuery();
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    password: Yup.string().required(LOCALIZATIONS["This field is required."]),
    password2: Yup.string().required(LOCALIZATIONS["This field is required."]),
  });

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    axios
      .put("/user/change-password/", {
        ...data,
        phone: query.get("phone"),
        verification_code: query.get("verification_code"),
      })
      .then((res) => {
        dispatch(openSnackbar({ message: "Ձեր գաղտնաբառը փոփոխվել է" }));
        navigate("/");
      })
      .catch((err) => {
        if (err && err["password"] && err["password"][0]) {
          setError("password", { message: err.password[0] });
        }
        if (err && err["password2"] && err["password2"][0]) {
          setError("password2", { message: err.password2[0] });
        }
        if (err && err["detail"]) {
          dispatch(
            openSnackbar({ message: "Հաստատման կոդը սխալ է", type: "error" }),
          );
        }
      });
  };

  return (
    <OverlayBox>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h5 className="color--black">Վերականգնել գաղտնաբառը</h5>
        <div className="single-input">
          <input
            className="cr-round--lg"
            type="password"
            placeholder="Գաղտնաբառ"
            {...register("password")}
          />
          {errors.password && (
            <small className="text-danger">{errors.password.message}</small>
          )}
        </div>
        <div className="single-input">
          <input
            className="cr-round--lg"
            type="password"
            placeholder="Կրկնել գաղտնաբառը"
            {...register("password2")}
          />
          {errors.password2 && (
            <small className="text-danger">{errors.password2.message}</small>
          )}
        </div>
        <div className="single-input">
          <button type="submit" className="food__btn">
            <span>Շարունակել</span>
          </button>
        </div>
      </form>
    </OverlayBox>
  );
}
