import { dispatch, useSelector } from "redux/store";
import CartItem from "./CartItem";
import { Link, useNavigate } from "react-router-dom";
import React, { useCallback, useMemo } from "react";
import { openSnackbar } from "redux/slices/ui";
import useCart from "../../hooks/useCart";

export default function Cart() {
  const navigate = useNavigate();
  const { cartItems, subtotal, total, delivery, cartCount } = useCart();
  const { minimumOrderCount } = useSelector((state) => state.settings);

  const hasError = useMemo(
    () => cartCount < minimumOrderCount,
    [cartCount, minimumOrderCount],
  );
  const errorMessage = useMemo(
    () =>
      `Մինիմալ պատվերի քանակը ${minimumOrderCount} է, ավելացրեք ևս ${minimumOrderCount - cartCount} ապրանք`,
    [minimumOrderCount, cartCount],
  );

  const onOrderClick = useCallback(() => {
    if (hasError) {
      dispatch(openSnackbar({ message: errorMessage, type: "error" }));
    }
  }, [errorMessage, hasError]);

  return (
    <div className="cartbox-wrap is-visible">
      <div className="body-overlay" onClick={() => navigate("/")}></div>
      <div className="cartbox text-right">
        <button className="cartbox-close" onClick={() => navigate("/")}>
          <i className="zmdi zmdi-close"></i>
        </button>
        <div className="cartbox__inner text-left">
          {cartCount > 0 ? (
            <>
              <div className="cartbox__items">
                {cartItems.map((item, index) => (
                  <CartItem key={index} item={item} />
                ))}
              </div>
              {hasError && (
                <small className="text-danger">{errorMessage}</small>
              )}
              <div className="cartbox__total">
                <ul>
                  <li>
                    <span className="cartbox__total__title">Ենթագումար</span>
                    <span className="price">{subtotal}֏</span>
                  </li>
                  <li className="shipping-charge">
                    <span className="cartbox__total__title">Առաքման վճար</span>
                    <span className="price">{delivery}֏</span>
                  </li>
                  <li className="grandtotal">
                    Ընդամենը<span className="price">{total}֏</span>
                  </li>
                </ul>
              </div>
              <div className="cartbox__buttons">
                {/*<a className="food__btn" href="cart.html"><span>View cart</span></a>*/}
                <Link
                  className="food__btn"
                  to={!hasError && "/checkout"}
                  onClick={onOrderClick}
                >
                  <span>Պատվիրել</span>
                </Link>
              </div>
            </>
          ) : (
            <div className="cartbox__total">
              <ul>
                <li className="grandtotal">Ձեր զամբյուղը դատարկ է</li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
