export const LOCALIZATIONS = {
  "This field is required.": "Այս դաշտը պետք է լրացված լինի",
  "This field may not be blank.": "Այս դաշտը պետք է լրացված լինի",
  "Invalid phone number.": "Մուտքագրեք վավեր հեռախոսահամար",
  "This password is too short. It must contain at least 6 characters.":
    "Մուտքագրեք առնվազն 6 նիշ",
  "This password is too common.": "Այս գաղտնաբառը չափազանց պարզ է",
  "This phone number is already registered.":
    "Այս հեռախոսահամարը արդեն գրանցված է",
  "Password fields didn't match.": "Գաղտնաբառերը չեն համընկնում",
  "Incorrect phone or password.": "Սխալ հեռախոսահամար կամ գաղտնաբառ",
  "Orders can only be created during working hours from Monday to Saturday.":
    "Պատվերները կարող են ընդունվել ժամը 9-ից 16-ը ընկած ժամանակահատվածում երկուշաբթիից շաբաթ",
};
