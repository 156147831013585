import Pagination from "./Pagination";

export default function Table({
  list,
  children,
  count,
  page,
  rowsPerPage,
  onPageChange,
  onAdd,
}) {
  return (
    <>
      {list ? (
        list.length > 0 ? (
          <>
            <table className="table bg-white table-bordered">{children}</table>
            <Pagination
              count={count}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={onPageChange}
            />
          </>
        ) : (
          <h3>No items</h3>
        )
      ) : (
        <h3>Loading...</h3>
      )}
    </>
  );
}
